import Transform from "images/key-capabilities/Transform.png";
import TrimSplit from "images/key-capabilities/TrimSplit.png";
import VideoCollage from "images/key-capabilities/VideoCollage.png";
import Stickers from "images/key-capabilities/Stickers.png";
import Filters from "images/key-capabilities/Filters.png";
import AudioOverlays from "images/key-capabilities/AudioOverlays.png";
import CustomizableUI from "images/key-capabilities/CustomizableUI.png";
import vesdkImage1 from "../../../../.remote/gatsby-imgly-source/main/docs/shared/images/vesdk.png";
import vesdkImage2 from "../../../../.remote/gatsby-imgly-source/main/docs/shared/images/vesdk2.png";
import * as React from 'react';
export default {
  Transform,
  TrimSplit,
  VideoCollage,
  Stickers,
  Filters,
  AudioOverlays,
  CustomizableUI,
  vesdkImage1,
  vesdkImage2,
  React
};