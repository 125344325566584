import Transform from 'images/key-capabilities/Transform.png';
import TrimSplit from 'images/key-capabilities/TrimSplit.png';
import VideoCollage from 'images/key-capabilities/VideoCollage.png';
import Stickers from 'images/key-capabilities/Stickers.png';
import Filters from 'images/key-capabilities/Filters.png';
import AudioOverlays from 'images/key-capabilities/AudioOverlays.png';
import CustomizableUI from 'images/key-capabilities/CustomizableUI.png';
import * as React from 'react';
export default {
  Transform,
  TrimSplit,
  VideoCollage,
  Stickers,
  Filters,
  AudioOverlays,
  CustomizableUI,
  React
};