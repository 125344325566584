import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Disclaimer = makeShortcode("Disclaimer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Disclaimer mdxType="Disclaimer">
      <h4>{`Free Trial`}</h4>
      <p>{`Our tech is modified to be used for testing purposes without a
license key. To start testing just follow this Get Started guide and leave out
the step of entering the commercial license keys. The editor will simply
render a watermark over the preview and final results. And in case you need
any technical assistance, make sure to reach out to us:
`}<a parentName="p" {...{
          "href": "https://img.ly/support"
        }}>{`https://img.ly/support`}</a>{`. We’ll be glad to help.`}</p>
    </Disclaimer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      