import * as React from 'react';

import { Button } from '@imgly/imgly-website-components';

import { ReactComponent as GitHubLogo } from '../assets/images/imgly/github.svg';

import {
  codeButtonsItem,
  codeButtonsList,
  githubButton,
} from './code_buttons.module.css';

function CodeButtons({ githubPath }) {
  const githubUrl = `https://github.com/${githubPath}`;
  return (
    <ul className={codeButtonsList}>
      <li className={codeButtonsItem}>
        <Button
          className={githubButton}
          render={<a />}
          href={githubUrl}
          rel="noreferrer"
          target="_blank"
          leadingIcon={<GitHubLogo />}
          small
        >
          View on GitHub
        </Button>
      </li>
    </ul>
  );
}
export default CodeButtons;
