// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-imgly-labs-gatsby-theme-imgly-docs-src-templates-basic-template-basic-template-tsx": () => import("./../../../../../node_modules/@imgly-labs/gatsby-theme-imgly-docs/src/templates/BasicTemplate/BasicTemplate.tsx" /* webpackChunkName: "component---node-modules-imgly-labs-gatsby-theme-imgly-docs-src-templates-basic-template-basic-template-tsx" */),
  "component---node-modules-imgly-labs-gatsby-theme-imgly-docs-src-templates-documentation-template-documentation-template-tsx": () => import("./../../../../../node_modules/@imgly-labs/gatsby-theme-imgly-docs/src/templates/DocumentationTemplate/DocumentationTemplate.tsx" /* webpackChunkName: "component---node-modules-imgly-labs-gatsby-theme-imgly-docs-src-templates-documentation-template-documentation-template-tsx" */),
  "component---node-modules-imgly-labs-gatsby-theme-imgly-docs-src-templates-framework-template-framework-template-tsx": () => import("./../../../../../node_modules/@imgly-labs/gatsby-theme-imgly-docs/src/templates/FrameworkTemplate/FrameworkTemplate.tsx" /* webpackChunkName: "component---node-modules-imgly-labs-gatsby-theme-imgly-docs-src-templates-framework-template-framework-template-tsx" */),
  "component---node-modules-imgly-labs-gatsby-theme-imgly-docs-src-templates-interactive-code-template-interactive-code-template-tsx": () => import("./../../../../../node_modules/@imgly-labs/gatsby-theme-imgly-docs/src/templates/InteractiveCodeTemplate/InteractiveCodeTemplate.tsx" /* webpackChunkName: "component---node-modules-imgly-labs-gatsby-theme-imgly-docs-src-templates-interactive-code-template-interactive-code-template-tsx" */)
}

